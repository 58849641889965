<template>
    <TabsList
        v-bind="delegatedProps"
        :class="cn('grid grid-cols-1 md:grid-flow-col auto-cols-fr rounded-md', props.class)">
        <slot />
    </TabsList>
</template>
<script setup lang="ts">
import { TabsList, type TabsListProps } from "radix-vue";
import { computed, type HTMLAttributes } from "vue";

const props = defineProps<TabsListProps & { class?: HTMLAttributes["class"] }>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});
</script>
